import dividerWhite from "assets/images/dividerWhite.svg";
import dividerGreen from "assets/images/dividerGreen.svg";
import location from "assets/images/location.png";
import call from "assets/images/call.png";
import * as Yup from "yup";
import { useFormik } from "formik";
import { axiosPehalApi } from "services/api";
import { ToastContainer, toast } from "react-toastify";

const initialValues = {
  name: "",
  email: "",
  phone_number: "",
  enquiry_type: 1,
  message: "",
};

const validationSchema = Yup.object().shape({
  name: Yup.string().trim().required("please enter name."),
  email: Yup.string()
    .trim()
    .email("Invalid email.")
    .required("please enter email."),
  phone_number: Yup.string()
    .matches(/^[0-9]{10}$/, "Phone number must be exactly 10 digits.")
    .required("Phone number is required."),
  message: Yup.string().trim().required("please enter message."),
});

const ContactUs = () => {
  const onSubmit = async (values) => {
    const response = await axiosPehalApi.post("saveEnquiry", {
      ...values,
      enquiry_type: +values?.enquiry_type,
    });
    if (response?.data?.statusCode === 200) {
      toast.success(
        "Your inquiry send successfully. Pehal gigtech pvt ltd member connect you soon.",
        {
          position: "top-right",
          autoClose: 5000,
        }
      );
      resetForm();
    }
  };

  const { handleBlur, handleChange, handleSubmit, values, resetForm } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit,
    });

  return (
    <section className="section contact-section px-5" id="connect-us">
      {/* <div className="container"> */}
      <ToastContainer />
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-7 col-xl-8 locations_info">
          <div className="maps">
            <h2>GLOBAL PRESENCE</h2>
            <h6>OUR OFFICES ARE LOCATED AT:</h6>
            <img src={dividerWhite} alt="" />
            <ul>
              <li>
                <h5 className="m-0">Registered Address </h5>
                <div className="w-100 d-inline-flex align-items-center mt-3 text-white ps-2">
                  <img src={location} className="me-2" alt="" /> A-501,
                  Signature-2 Sarkhej Sanad Highway, Sarkhej Sanand Ahmedabad ,
                  Gujarat
                </div>
                <div className="w-100 d-inline-flex align-items-center mt-3 text-white ps-2">
                  <img src={call} className="me-2" alt="" />
                  <a href="tel:+918356988262" className="mobile_number">
                    +91 835 698 8262
                  </a>
                </div>
              </li>
              <li>
                <h5 className="m-0">Mumbai Office </h5>
                <div className="w-100 d-inline-flex align-items-center mt-3 text-white ps-2">
                  <img src={location} className="me-2" alt="" /> Lilac 5, Godrej
                  Sky Garden, Panvel 410206
                </div>
                <div className="w-100 d-inline-flex align-items-center mt-3 text-white ps-2">
                  <img src={call} className="me-2" alt="" />
                  <a href="tel:++917977728832" className="mobile_number">
                    +91 797 772 8832
                  </a>
                </div>
              </li>
              <li>
                <h5 className="m-0">Gandhinagar Office </h5>
                <div className="w-100 d-inline-flex align-items-center mt-3 text-white ps-2">
                  <img src={location} className="me-2" alt="" /> Unit no. 2b/3 ,
                  ground floor at IT4, Infocity IT metropolis, Gandhinagar -
                  382009
                </div>
                <div className="w-100 d-inline-flex align-items-center mt-3 text-white ps-2">
                  <img src={call} className="me-2" alt="" />
                  <a href="tel:+919426272844" className="mobile_number">
                    +91 942 627 2844
                  </a>
                </div>
              </li>
              <li>
                <h5 className="m-0">UK Office </h5>
                <div className="w-100 d-inline-flex align-items-center mt-3 text-white ps-2">
                  <img src={location} className="me-2" alt="" /> 42, Trinity
                  Street, Dorchester, Dorset, England, UK DT1 1TT
                </div>
                <div className="w-100 d-inline-flex align-items-center mt-3 text-white ps-2">
                  <img src={call} className="me-2" alt="" />
                  <a href="tel:+447428247328" className="mobile_number">
                    +44 7428 247328
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-5 col-xl-4 contact_section_form">
          <div className="contact-form ps-4 py-4 rounded">
            <h2>SAY HELLO!</h2>
            <img src={dividerGreen} alt="" />
            <form onSubmit={handleSubmit}>
              <div className="col-12 d-flex flex-column mb-3">
                <label className="w-100 mb-0">Name</label>
                <input
                  className="w-100 mt-2 border border-light-gray rounded px-3 py-2 bg-white"
                  placeholder="Enter Name"
                  type="text"
                  id="focus-input"
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.name}
                />
              </div>
              <div className="col-12 d-flex flex-column mb-3">
                <label className="w-100 mb-0">Email</label>
                <input
                  className="w-100 mt-2 border border-light-gray rounded px-3 py-2 bg-white"
                  placeholder="Enter Email"
                  type="text"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.email}
                />
              </div>
              <div className="col-12 d-flex flex-column mb-3">
                <label className="w-100 mb-0">Phone No.</label>
                <input
                  className="w-100 mt-2 border border-light-gray rounded px-3 py-2 bg-white"
                  placeholder="Enter Phone no."
                  type="text"
                  name="phone_number"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.phone_number}
                />
              </div>
              <div className="col-12 d-flex flex-column mb-3">
                <label className="w-100 mb-0">Contact Email Inquiry</label>
                <select
                  className="w-100 mt-2 border border-light-gray rounded px-3 py-2 bg-white"
                  onChange={handleChange}
                  name="enquiry_type"
                  value={values?.enquiry_type}
                >
                  <option value="1">Gig Partners</option>
                  <option value="2">Gig Clients</option>
                </select>
              </div>
              <div className="col-12 d-flex flex-column mb-3">
                <label className="w-100 mb-0">Message</label>
                <textarea
                  className="w-100 mt-2 border border-light-gray rounded px-3 py-2 bg-white"
                  placeholder="Enter Message"
                  rows={4}
                  name="message"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.message}
                />
              </div>
              <div className="col-12 text-center">
                <button type="submit" className="contact-submit">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* </div> */}
    </section>
  );
};
export default ContactUs;
