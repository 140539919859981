import React from "react";
import bannerImage from "assets/images/banner-img.svg";
import bannerImage2 from "assets/images/banner-img-2.svg";
import bannerImage3 from "assets/images/banner-img-3.svg";
import whiteTick from "assets/images/white-tick.png";
import greenTick from "assets/images/green-tick.png";
import OwlCarousel from "react-owl-carousel";
import { toHaveFormValues } from "@testing-library/jest-dom/dist/matchers";

const content = [
  {
    title: "The most prominent",
    titleSpan: "Gig work platform for enterprises",
    endTitle: "in India.",
    subtitle:
      "The India’s leading gig work platform for enterprise-level tasks.",
    description:
      "We simplify intricate business requirements by breaking them down into manageable tasks, and we assume full responsibility for executing the entire scope of the work.",
    descPoints: [
      { point: "Pay for Deliverables/Outcomes" },
      { point: "Variable and On-demand capabilities" },
      { point: "Real Time Tracking and Updates" },
    ],
    isButton: true,
    image: bannerImage,
  },

  {
    title: "A Vast",
    titleSpan: "Network Comprising",
    endTitle: " More Than A Million Skilled",
    endSpan: "Gig Workers & 5000+ Partners.",
    description:
      "A Workforce Community With Diverse Skill Profiles, Primed For Immediate Deployment. We Uphold Stringent Standards Through Rigorous Selection, Training, And Onboarding Processes To Ensure Quality And Meet SLAs.",
    descPoints: [
      { point: "500 cities & 13000+ Pin codes" },
      { point: "Blue, Grey and White collar profiles" },
      { point: "Across 15+ Vernacular languages" },
    ],
    isButton: toHaveFormValues,
    image: bannerImage2,
  },

  {
    title: "A",
    titleSpan: "Technology Platform",
    endTitle: "  That Operates Without The Need For Coding.",
    description:
      "An Entirely Customizable Technology Stack Designed To Transform And Execute Even The Most Complex Workflows, Tailored Precisely To Meet Your Unique Business Requirements.",
    descPoints: [
      { point: "Execution App for gig workers" },
      { point: "War Room for Pehal Operation Team" },
      { point: "Client Dashboard for Enterprises" },
    ],
    isButton: true,
    image: bannerImage3,
  },
];

const HomeContent = () => {
  const scrollToRegister = (id) => {
    const section = document.getElementById(id);
    if (section) {
      const yOffset = section.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({ top: yOffset, behavior: "smooth" });
    }
  };

  const options = {
    items: 1,
    margin: 30,
    dots: false,
    nav: true,
    smartSpeed: 2000,
    navText: [
      '<i class="fas fa-chevron-left"></i>',
      '<i class="fas fa-chevron-right"></i>',
    ],
    loop: true,
    responsiveClass: true,
    vertical: true,
    // autoplay: true,
    // autoplayTimeout: 3000,
  };

  return (
    <>
      <section className="section home-banner row-middle" id="home">
        <div className="main-content" id="home">
          <div className="container">
            <OwlCarousel
              id="developers-slider"
              className="owl-carousel owl-theme developers-slider aos"
              loop
              {...options}
            >
              {content?.map((x, i) => {
                return (
                  <React.Fragment key={i}>
                    <div className="row align-items-center">
                      <div className="col-md-7 col-lg-7">
                        <div className="home-content aos" data-aos="fade-up">
                          <h1>
                            {x?.title}{" "}
                            {x?.titleSpan && <span>{x?.titleSpan}</span>}{" "}
                            {x?.endTitle}{" "}
                            {x?.endSpan && <span>{x?.endSpan}</span>}{" "}
                          </h1>
                          {x?.subtitle && <h3>{x?.subtitle}</h3>}
                          <p>{x?.description}</p>
                          {!!x?.descPoints?.length &&
                            x?.descPoints?.map((y) => {
                              return (
                                <div className="home-points mb-2">
                                  <div className="d-flex align-items-center">
                                    <img src={greenTick} alt="" />
                                    <p>{y?.point}</p>
                                  </div>
                                </div>
                              );
                            })}
                          {x?.isButton && (
                            <button
                              className="btn-pehal mt-2"
                              type="button"
                              onClick={() => scrollToRegister("register")}
                            >
                              Read More
                            </button>
                          )}
                        </div>
                      </div>
                      <div className="col-md-5 col-lg-5">
                        <img src={x?.image} alt="" className="w-100" />
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}
            </OwlCarousel>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeContent;
