import adani from "assets/images/company/adani.png";
import amazon from "assets/images/company/amazon.png";
import au_bank from "assets/images/company/au-bank.png";
import bharatpe from "assets/images/company/bharatpe.png";
import care_essentials from "assets/images/company/care-essentials.png";
import gati_kwe from "assets/images/company/gati-kwe.png";
import hester_biosciences from "assets/images/company/hester-biosciences.png";
import indiamart from "assets/images/company/indiamart.png";
import kotak_mahindra from "assets/images/company/kotak-mahindra.png";
import paytm from "assets/images/company/paytm.png";
import snapdeal from "assets/images/company/snapdeal.png";
import torrent from "assets/images/company/torrent.png";
import viacom_18 from "assets/images/company/viacom-18.png";
import yes_bank from "assets/images/company/yes-bank.png";
import zomato from "assets/images/company/zomato.png";
import OwlCarousel from "react-owl-carousel";

const options = {
  items: 8,
  margin: 30,
  dots: false,
  nav: false,
  smartSpeed: 2000,
  navText: [
    '<i class="fas fa-chevron-left"></i>',
    '<i class="fas fa-chevron-right"></i>',
  ],
  loop: true,
  responsiveClass: true,
  responsive: {
    0: {
      items: 2,
    },
    768: {
      items: 3,
    },
    1170: {
      items: 6,
    },
  },
};

const Clients = () => {
  return (
    <section className="section top-company" id="clients">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="section-header text-center aos" data-aos="fade-up">
              <h2 className="client-title">
                Trusted By <span>100+</span> Clients{" "}
              </h2>
            </div>
          </div>
        </div>
        <OwlCarousel
          id="company-slider"
          className="owl-carousel owl-theme testimonial-slider aos"
          data-aos="fade-up"
          {...options}
        >
          <div className="company-logos">
            <img src={adani} alt="adani" />
          </div>
          <div className="company-logos">
            <img src={amazon} alt="amazon" />
          </div>
          <div className="company-logos">
            <img src={au_bank} alt="logo-3" />
          </div>
          <div className="company-logos">
            <img src={bharatpe} alt="logo-4" />
          </div>
          <div className="company-logos">
            <img src={care_essentials} alt="logo-5" />
          </div>
          <div className="company-logos">
            <img src={gati_kwe} alt="logo-6" />
          </div>
          <div className="company-logos">
            <img src={hester_biosciences} alt="logo-7" />
          </div>
          <div className="company-logos">
            <img src={indiamart} alt="logo-1" />
          </div>
          <div className="company-logos">
            <img src={kotak_mahindra} alt="logo-1" />
          </div>
          <div className="company-logos">
            <img src={paytm} alt="logo-1" />
          </div>
          <div className="company-logos">
            <img src={snapdeal} alt="logo-1" />
          </div>
          <div className="company-logos">
            <img src={torrent} alt="logo-1" />
          </div>
          <div className="company-logos">
            <img src={viacom_18} alt="logo-1" />
          </div>
          <div className="company-logos">
            <img src={yes_bank} alt="logo-1" />
          </div>
          <div className="company-logos">
            <img src={zomato} alt="logo-1" />
          </div>
        </OwlCarousel>
      </div>
    </section>
  );
};
export default Clients;
