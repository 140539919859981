import React from "react";
import { Button, Modal } from "react-bootstrap";

const OfferModal = ({ show = false, data, toggleModal = () => {} }) => {
  return (
    <Modal show={show} onHide={toggleModal} size="lg" centered>
      <Modal.Header className="modal_close_icon" closeButton></Modal.Header>
      <Modal.Body className="modal_list_view">
        <ul>
          {!!data?.length &&
            data?.map((x, i) => {
              return (
                <React.Fragment key={i}>
                  <li>
                    <div>
                      {x?.icon && <img src={x?.icon} alt={`offer ${i + 1}`} />}
                    </div>
                    <div>{x?.value}</div>
                  </li>
                </React.Fragment>
              );
            })}
        </ul>
      </Modal.Body>
    </Modal>
  );
};
export default OfferModal;
