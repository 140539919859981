import axios from "axios";
import Cookies, { cookieKeys } from "./cookies";
import { clearLocal } from "./localStorage";
import routeConstants from "routes/routeConstants";

const API_URL_ACCOUNT = "https://pahelapi.quickoninfotech.com/api/v1/";

class Axios {
  constructor(baseURL) {
    this.axios = axios.create({
      baseURL,
    });

    this.axios.interceptors.request.use(this._requestMiddleware);

    this.axios.interceptors.response.use(
      this._responseMiddleware,
      this._responseErr
    );
  }

  _requestMiddleware = (req) => {
    // Send Bearer token on every request
    const token = Cookies.get(cookieKeys.Token);
    if (!!token)
      req.headers.authorization = token.startsWith("Bearer ")
        ? token
        : "Bearer " + token;
    return req;
  };

  _responseMiddleware = (response) => {
    //  Do something on every success full response

    if (response?.data?.data?.token) {
      Cookies.set(cookieKeys.Token, response.data.data.token);
    }
    return response;
  };

  _responseErr = (error) => {
    if (
      (error?.response?.data?.message).toString().toLowerCase() ===
      "permission reverted"
    ) {
      window.location.replace("/");
    }
    if (error?.response?.status === 401) {
      window.location.replace(`${routeConstants?.login}`);
      Cookies.clear();
      clearLocal();
      return Promise.reject(error);
    }
    return Promise.reject(error);
  };
}

const axiosPehalApi = new Axios(API_URL_ACCOUNT).axios;

export { axiosPehalApi };
