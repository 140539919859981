import joinUs from "assets/images/join-us.svg";
import { setLocal } from "services/localStorage";

const JoinUs = () => {
  const btnScroll = (id) => {
    if (id) {
      setLocal("id", id);
      const section = document.getElementById(id);
      section?.scrollIntoView({ behavior: "smooth" });
    }
    const focusedInput = document.getElementById("focus-input");
    if (focusedInput) {
      focusedInput?.focus();
    }
  };
  return (
    <section className="section join-us" id="join-us">
      <div className="container">
        <div className="row">
          <div className="col-md-6 d-flex align-items-center">
            <div className="app-version-blk aos" data-aos="fade-up">
              <h4 className="text-white">We are Hiring</h4>
              <h1 className="text-white">Come and Join US</h1>
              <p className="text-white">
                Join our growing GiG community of satisfied users and discover
                the endless possibilities with Pehal. Download now and
                experience the future of seamless task access and productivity
                with real time earning.
              </p>
              <button
                className="btn-pehal w-50"
                onClick={() => btnScroll("join-us")}
              >
                Join Us
              </button>
            </div>
          </div>
          <div className="col-md-6">
            <div className="app-version-img text-center aos" data-aos="fade-up">
              <img className="img-fluid join-is-image" src={joinUs} alt="App" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default JoinUs;
