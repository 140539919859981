import appVersion from "assets/images/app-version.svg";
import app1 from "assets/images/app-01.png";
import app2 from "assets/images/app-02.png";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const AppVersion = () => {
  return (
    <section className="section app-version" id={"get-app"}>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="app-version-img text-center aos" data-aos="fade-up">
              <img className="img-fluid" src={appVersion} alt="App" />
            </div>
          </div>
          <div className="col-md-6 d-flex align-items-center">
            <div className="app-version-blk aos" data-aos="fade-up">
              <h2>
                Get More In Our Application and Enjoy The Mobile App Version
              </h2>
              <p>
                Welcome to the download page for Pehal GigTech PVT LTD! Here,
                you'll find all the information you need to get started with our
                amazing app. Whether you're using iOS, Android, or another
                platform, we've got you covered.
              </p>
              <h5>Download our Mobile app in Both Platform</h5>
              <div className="app-img-inside">
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>Coming Soon</Tooltip>}
                >
                  <img className="img-fluid" src={app1} alt="App" />
                </OverlayTrigger>
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>Coming Soon</Tooltip>}
                >
                  <img className="img-fluid" src={app2} alt="App" />
                </OverlayTrigger>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default AppVersion;
