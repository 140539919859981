import customerExperience from "assets/images/offers/customer_experience.png";
import revenueAudit from "assets/images/offers/revenue_audit.png";
import sellerAudit from "assets/images/offers/seller_audit.png";
import complianceAudit from "assets/images/offers/compliance_audit.png";
import inventoryAudit from "assets/images/offers/inventory_audit.png";
import warehouseAudit from "assets/images/offers/warehouse_audit.png";
import shopperAudit from "assets/images/offers/shopper_audit.png";
import pointAudit from "assets/images/offers/point_audit.png";

import houseSurvey from "assets/images/offers/house_survey.png";
import employeeSurvey from "assets/images/offers/employee_survey.png";
import healthcareSurvey from "assets/images/offers/healthcare_survey.png";
import educationSurvey from "assets/images/offers/education_survey.png";
import customerSurvey from "assets/images/offers/customer_survey.png";
import businessSurvey from "assets/images/offers/business_survey.png";
import retailSurvey from "assets/images/offers/retail_survey.png";
import socialSurvey from "assets/images/offers/social_survey.png";
import politicalSurvey from "assets/images/offers/political_survey.png";
import internalSurvey from "assets/images/offers/internal_survey.png";

import retailMarket from "assets/images/offers/retail_market.png";
import tacticsMarket from "assets/images/offers/tactics_market.png";
import salesMarket from "assets/images/offers/sales_market.png";
import merchandisingMarket from "assets/images/offers/strategy_market.png";
import storeMarket from "assets/images/offers/store_market.png";
import recurringMarket from "assets/images/offers/recurring_market.png";
import retailManagingMarket from "assets/images/offers/retailManaging_market.png";
import productMarket from "assets/images/offers/product_market.png";

import Auditing from "assets/images/offers/auditing.svg";
import Survey from "assets/images/offers/survey.svg";
import Retail from "assets/images/offers/retail.svg";
import Business from "assets/images/offers/business-development.svg";
import Background from "assets/images/offers/background-verification.svg";
import Catalog from "assets/images/offers/catalog.svg";
import Virtual from "assets/images/offers/virtual-call.svg";
import Experts from "assets/images/offers/experts.svg";

import promotoresDeployment from "assets/images/offers/promotores_deployment.png";
import productLaunch from "assets/images/offers/product_launch.png";
import samplingActivity from "assets/images/offers/sampling_activity.png";
import appDownload from "assets/images/offers/app_download.png";
import brandAwareness from "assets/images/offers/brand_awareness.png";
import leafletDistribution from "assets/images/offers/leaflet_distribution.png";
import dataCollection from "assets/images/offers/data_collection.png";
import leadGeneration from "assets/images/offers/lead_generation.png";

import addressVerification from "assets/images/offers/address_verification.png";
import educationDegreeVerification from "assets/images/offers/education_degree_verification.png";
import policeVerification from "assets/images/offers/police_verification.png";
import employmentHistory from "assets/images/offers/employment_history.png";
import nationalIDCheck from "assets/images/offers/national_ID_check.png";
import courtRecordsCheck from "assets/images/offers/court_records_check.png";
import fieldSurveys from "assets/images/offers/field_surveys.png";

import videoModeration from "assets/images/offers/video_moderation.png";
import imageModeration from "assets/images/offers/image_moderation.png";
import audioModeration from "assets/images/offers/audio_moderation.png";
import annotation from "assets/images/offers/annotation.png";
import qualityCheck from "assets/images/offers/quality_check.png";
import catalogContent from "assets/images/offers/catalog_content.png";
import catalogContentRectification from "assets/images/offers/catalog_content_rectification.png";
import catalogImageQC from "assets/images/offers/catalog_image_QC.png";
import catalogImageRectification from "assets/images/offers/catalog_image_rectification.png";

import informativeCalls from "assets/images/offers/informative_calls.png";
import serviceCalls from "assets/images/offers/service_calls.png";
import feed_backCalls from "assets/images/offers/feed_back_calls.png";
import reactivationCalls from "assets/images/offers/reactivation_calls.png";
import form_fillingCalls from "assets/images/offers/form_filling_calls.png";
import KYCCalls from "assets/images/offers/KYC_calls.png";
import customerSupportCalls from "assets/images/offers/customer_support_calls.png";
import meetingSchedulingCalls from "assets/images/offers/meeting_scheduling_calls.png";

import softwareDeveloper from "assets/images/offers/software_developer.png";
import periodImmediatelyAvailable from "assets/images/offers/period_immediately_available.png";
import nicheTechTalents from "assets/images/offers/niche_tech_talents.png";
import resourceManagement from "assets/images/offers/resource_management.png";
import skillsCovered from "assets/images/offers/skills_covered.png";

const offerContent = [
  {
    title: "Auditing: Mystery Audit & Non- Mystery Audit",
    text: "Mystery Audit & Non- Mystery Audit",
    image: Auditing,
    content: [
      { icon: customerExperience, value: "Customer Experience Audit" },
      { icon: revenueAudit, value: "Revenue Leakage Audit" },
      { icon: sellerAudit, value: "Sellers Fund Protection Audit" },
      { icon: complianceAudit, value: "Compliance Audit" },
      { icon: inventoryAudit, value: "Inventory Audit" },
      { icon: warehouseAudit, value: "Factory and Warehouse Audit" },
      { icon: shopperAudit, value: "Mystery shopper Audit" },
      { icon: pointAudit, value: "Point of Interest Data Audit" },
    ],
  },
  {
    title: "Survey",
    text: "From email to SMS surveys, the common denominator that determines effectiveness is the questions. Different question and answer types promote multiple answers, even for similar questions.",
    image: Survey,
    content: [
      { icon: houseSurvey, value: "Household Survey" },
      { icon: employeeSurvey, value: "Employment and Unemployment Survey" },
      { icon: healthcareSurvey, value: "Healthcare Survey" },
      { icon: educationSurvey, value: "Education Survey" },
      { icon: customerSurvey, value: "Consumer Sentiment Survey" },
      { icon: businessSurvey, value: "Business and Industrial Surveys" },
      { icon: retailSurvey, value: "Retail and Market Surveys" },
      { icon: socialSurvey, value: "Social Surveys" },
      { icon: politicalSurvey, value: "Political Opinion Polls" },
      { icon: internalSurvey, value: "Internal Employee Surveys" },
    ],
  },
  {
    title: "Retail Activation",
    text: "Activating your brand means creating a connection between your company and its customers. It's about finding ways to bring your brand to life so that people can interact with it on a personal level.",
    image: Retail,
    content: [
      { icon: retailMarket, value: "Retail marketing" },
      { icon: tacticsMarket, value: "Retail sales tactics" },
      { icon: salesMarket, value: "Point of sale (POS) marketing" },
      { icon: merchandisingMarket, value: "Merchandising strategies" },
      { icon: storeMarket, value: "Store / Retail Activation" },
      { icon: recurringMarket, value: "Beat plans for Recurring orders" },
      { icon: retailManagingMarket, value: "Retail engagement" },
      { icon: productMarket, value: "Retail product placement" },
    ],
  },
  {
    title: "Business Development",
    text: "If you work in business development and you often wonder what activities you should be focusing on, you came to the right place. And you will be happy to know that you’re not the only one to struggle to create a successful plan of action.",
    image: Business,
    content: [
      { icon: promotoresDeployment, value: "In Store Promotores Deployment" },
      { icon: productLaunch, value: "New Product Launch at POS" },
      { icon: samplingActivity, value: "Sampling Activity" },
      { icon: appDownload, value: "App Download" },
      { icon: brandAwareness, value: "Brand Awareness" },
      { icon: leafletDistribution, value: "Leaflet Distribution" },
      { icon: dataCollection, value: "Data Collection" },
      { icon: leadGeneration, value: "Lead Generation " },
    ],
  },
  {
    title: "Background Verification",
    text: "Our employee background check policy refers to our guidelines for investigating our job candidates’ backgrounds as part of our hiring process. Background checks help us",
    image: Background,
    content: [
      { icon: addressVerification, value: "Address Verification" },
      {
        icon: educationDegreeVerification,
        value: "Education Degree Verification",
      },
      { icon: policeVerification, value: "Police Verification" },
      { icon: employmentHistory, value: "Past Employment History" },
      { icon: nationalIDCheck, value: "National ID Check" },
      { icon: courtRecordsCheck, value: "Court Records Check" },
      { icon: fieldSurveys, value: "Field Surveys" },
    ],
  },
  {
    title: "Cataloguing",
    text: "A well designed catalog and its content are pivotal for the success of any eCommerce business. While the design element grabs the attention of buyers, content engages them and takes them to the end of the sales funnel. Identifying the USPs of the products and presenting them in a lucid yet persuasive style calls for expert eCommerce content writers. ",
    image: Catalog,
    content: [
      { icon: videoModeration, value: "Video Moderation" },
      { icon: imageModeration, value: "Image Moderation" },
      { icon: audioModeration, value: "Audio Moderation" },
      { icon: annotation, value: "Annotation" },
      { icon: qualityCheck, value: "Quality Check" },
      {
        icon: catalogContent,
        value: "Catalog Content QC (Existing and New Inflow)",
      },
      {
        icon: catalogContentRectification,
        value: "Catalog Content Rectification",
      },
      { icon: catalogImageQC, value: "Catalog Image QC" },
      { icon: catalogImageRectification, value: "Catalog Image Rectification" },
    ],
  },
  {
    title: "Virutal Call Centres",
    text: "Visit our call center agent training and evaluation strategies",
    image: Virtual,
    content: [
      { icon: informativeCalls, value: "Informative Calls" },
      { icon: serviceCalls, value: "Service Calls" },
      { icon: feed_backCalls, value: "Feed Back Calls" },
      { icon: reactivationCalls, value: "Reactivation Calls" },
      { icon: form_fillingCalls, value: "Form Filling Calls" },
      { icon: KYCCalls, value: "KYC Calls" },
      { icon: customerSupportCalls, value: "Customer Support Calls" },
      { icon: meetingSchedulingCalls, value: "Meeting Scheduling Calls" },
    ],
  },
  {
    title: "Pehal Experts",
    text: "Share personal stories detailing how you got into the business. Tell readers how and why you did it, your struggles, and how you overcame them.",
    image: Experts,
    content: [
      {
        icon: softwareDeveloper,
        value: "Hire Vetted Software developer on contract",
      },
      {
        icon: periodImmediatelyAvailable,
        value: "No notice period immediately available",
      },
      { icon: nicheTechTalents, value: "Unique and Niche tech talents" },
      { icon: resourceManagement, value: "Resource management 24X7" },
      { icon: skillsCovered, value: "75+ Skills Covered" },
    ],
  },
];

export default offerContent;
